import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./Admin.css";
import restService from "../../services/RestService";

const Admin = () => {
  const endpoint = "/v0/website-highlight";
  const [inputText, setInputText] = useState("");
  const [isImportant, setIsImportant] = useState(false);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await restService.get(endpoint);
        setData(response);
        console.log(response);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleSave = async () => {
    try {
      const newHighlight = {
        text: inputText,
        isImportant: isImportant,
      };

      const response = await restService.post(endpoint, newHighlight);
      setData(response);

      // Clear the input fields
      setInputText("");
      setIsImportant(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDelete = async (highlightId) => {
    try {
      const response = await restService.delete(`${endpoint}/${highlightId}`);
      setData(response);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <h2>Highlight Component</h2>
      <div>
        <label>
          Input Text:
          <input
            type="text"
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
          />
        </label>
      </div>
      <div>
        <label>
          Important:
          <input
            type="checkbox"
            checked={isImportant}
            onChange={() => setIsImportant(!isImportant)}
          />
        </label>
      </div>
      <div>
        <button onClick={handleSave}>Save</button>
      </div>
      <div>
        <h3>Existing Highlights</h3>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <ul>
            {data?.content?.highlights.map((highlight, index) => (
              <li key={highlight.id}>
                {highlight.text} {highlight.isImportant && "(Important)"}
                <button onClick={() => handleDelete(index)}>Delete</button>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

Admin.propTypes = {};

Admin.defaultProps = {};

export default Admin;
