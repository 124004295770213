import React, { lazy, Suspense } from "react";
import "./App.css";
import Navbar from "./components/Navbar/Navbar";
import Carousel from "./components/Carousel/Carousel";
import About from "./components/About/About";
import Admin from "./components/Admin/Admin";
import Contact from "./components/Contact/Contact";
import RecentHighlight from "./components/RecentHighlight/RecentHighlight";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ServicesPage from "./pages/service/Service.page";
const Video = lazy(() => import("./components/Video/Video"));

function App() {
  return (
    <div id="app">
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Navbar />
                <div id="home">
                  <Carousel />
                </div>
                <RecentHighlight title={"Highlights"} />
                <Suspense fallback={<div>Loading...</div>}>
                  <Video />
                </Suspense>
                <About />
                <Contact />
              </>
            }
          />
          <Route path="/admin" element={<Admin />} />
          <Route path="/services" element={<ServicesPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
