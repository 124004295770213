import React from "react";
import "./NavbarV1.css";
import { Link } from "react-router-dom";

const NavbarV1 = () => {
  return (
    <div className="navbar-v1-container">
      <div className="left-section">
        <div className="logo">
          <img src="logoV3.jpg" alt="Logo" />
        </div>
        <div className="title">
          <h1>
            <Link to={"/"}>HM Series</Link>
          </h1>
        </div>
      </div>
      <div className="center-section">
        <nav>
          <div className="nav-item">
            <a href="#home">Home</a>
            <div className="nav-item-dropdown">
              <a href="#link1">Link 1</a>
              <a href="#link2">Link 2</a>
            </div>
          </div>
          <div className="nav-item">
            <a href="#about">About</a>
            <div className="nav-item-dropdown">
              <a href="#about-link1">About Link 1</a>
              <a href="#about-link2">About Link 2</a>
              <a href="#about-link3">About Link 3</a>
            </div>
          </div>
          <div className="nav-item">
            <a href="#contact">Contact</a>
            <div className="nav-item-dropdown">
              <a href="#email">Email</a>
              <a href="#phone">Phone</a>
              <a href="#whatsapp">WhatsApp</a>
            </div>
          </div>
        </nav>
      </div>
      <div className="right-section">
        <button>Login</button>
        <button>Register</button>
      </div>
    </div>
  );
};

export default NavbarV1;
