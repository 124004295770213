import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./Service.page.css";
import NavbarV1 from "../../components/NavbarV1/NavbarV1";
import Card from "../../components/Card/Card";

const ServicesPage = ({ showToast, message, duration }) => {
  const [show, setShow] = useState(showToast);

  useEffect(() => {
    setShow(showToast);

    const timer = setTimeout(() => {
      setShow(false);
    }, duration);

    return () => {
      clearTimeout(timer);
      setShow(false);
      duration = 0;
    };
  }, [showToast, duration]);

  return (
    <div className={`service-page-container`}>
      <NavbarV1 />
      <div className="card-container">
        <Card
          title="Reel Shoot"
          description={`A long description is a way to provide long alternative text for non-text elements,
           such as images. Generally, alternative text exceeding 250 characters, 
           which cannot be made more concise without making it less descriptive or meaningful, 
           should have a long description.`}
        />
        <Card
          title="Photo Shoot"
          description={`A long description is a way to provide long alternative text for non-text elements,
           such as images. Generally, alternative text exceeding 250 characters.`}
        />
        <Card
          title="Video Editing"
          description={`A long description is a way to provide long alternative text for non-text elements,
           such as images.`}
        />
      </div>
    </div>
  );
};

ServicesPage.propTypes = {};

ServicesPage.defaultProps = {};

export default ServicesPage;
