import React from "react";
import "./Card.css"; // Assuming you have a separate CSS file

const Card = ({ title, description }) => {
  return (
    <div className="card">
      <h2 className="title">{title}</h2>
      <p className="description">{description}</p>
    </div>
  );
};

export default Card;
