import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./RecentHighlight.css";
import restService from "../../services/RestService";
import LoaderV1 from "../loaders/loader-v1/LoaderV1";

const RecentHighlight = ({ title, showOnlyImportant }) => {
  const [recenetHighlights, setRecenetHighlights] = useState([]);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await restService.get("/v0/website-highlight");
        setData(response);
        setRecenetHighlights(response.data?.highlights);
        console.log(response);
      } catch (error) {
        console.error(error);
      } finally {
        setTimeout(() => setLoading(false), 5250);
      }
    };

    fetchData();
  }, []);

  return (
    <div id="recent-highlight" className="recent-highlight-container">
      {loading && (
        <div className="highlight-loader">
          <LoaderV1 />
        </div>
      )}
      <div className="events-wrapper">
        {title && <h2>{title}:</h2>}
        {recenetHighlights &&
          recenetHighlights
            .filter((highlight) => highlight.isImportant || !showOnlyImportant)
            .map((highlight, index) => (
              <div
                key={index}
                className={`event${
                  highlight.isImportant ? " important-event" : ""
                }`}
              >
                <span>{highlight.text}</span>
              </div>
            ))}
      </div>
    </div>
  );
};

RecentHighlight.propTypes = {
  title: PropTypes.string,
  showOnlyImportant: PropTypes.bool.isRequired,
};

RecentHighlight.defaultProps = {
  showOnlyImportant: false,
};

export default RecentHighlight;
