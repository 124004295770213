import config from "../config/config";

const restService = {
  get: async (endpoint, method = "GET", data = null) => {
    const url = `${config.apiBaseUrl}${endpoint}`;
    const headers = {
      "Content-Type": "application/json",
    };

    const options = {
      method,
      headers,
      body: data ? JSON.stringify(data) : null,
    };

    try {
      const response = await fetch(url, options);

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      return response.json();
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  },

  post: (endpoint, data) => restService.get(endpoint, "POST", data),
  put: (endpoint, data) => restService.get(endpoint, "PUT", data),
  delete: (endpoint) => restService.get(endpoint, "DELETE"),
};

export default restService;
